@import "https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap";
* {
  box-sizing: border-box;
}

body, html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Catamaran, sans-serif;
  font-size: 1.125rem;
  font-weight: 200;
}

img {
  max-width: 100%;
  display: inline-block;
}

i {
  color: #fff;
  font-size: 26px;
}

a, a:visited, a:hover {
  color: #000;
  text-decoration: none;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.navbar {
  z-index: 1000;
  background-color: #000;
  width: 100%;
  padding: .5rem 0;
  transition: transform .3s;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 4px 4px 8px #0003;
}

.navbar .container {
  padding: 0 1.25rem;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.flex {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__links, header .button-link {
  display: none;
}

.logo {
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  display: flex;
}

.hamburger {
  cursor: pointer;
}

.hamburger > span {
  transform-origin: 3px 1px;
  background-color: #fff;
  width: 26px;
  height: 2px;
  transition: all .3s;
  display: block;
}

.hamburger > span:not(:first-child) {
  margin-top: 5px;
}

.navbar.open .hamburger > span:first-child {
  transform: rotate(45deg);
}

.navbar.open .hamburger > span:nth-child(2) {
  opacity: 0;
}

.navbar.open .hamburger > span:last-child {
  transform: rotate(-45deg);
}

.overlay {
  z-index: 2;
  background-image: linear-gradient(#000, #0000);
  position: fixed;
  inset: 0;
}

.header__menu {
  color: #000;
  z-index: 3;
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  gap: .75rem;
  width: calc(100% - 2.75rem);
  margin-top: 2rem;
  padding: 2rem;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

.fade-in {
  animation: .25s forwards fade-in;
}

.fade-out {
  animation: .25s forwards fade-out;
}

.lock-scroll {
  overflow: hidden !important;
}

.btn {
  letter-spacing: .4em;
  text-align: center;
  text-indent: 2px;
  text-transform: uppercase;
  background: none;
  border: 1px solid #e1e1e1;
  padding: 13px 20px;
  font: 12px / 1.2 Oswald, sans-serif;
  text-decoration: none;
  transition: color .1s linear 50ms;
  display: inline-block;
  position: relative;
  color: #fff !important;
}

.btn:before {
  content: "";
  z-index: 1;
  opacity: 0;
  background: #e1e1e1;
  width: 100%;
  height: 1px;
  transition: height .2s, top .2s, opacity 0s linear .2s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.btn:after {
  transition: border .1s linear 50ms;
}

.btn .btn-inner {
  z-index: 2;
  position: relative;
}

.btn:hover {
  transition: color .1s;
  color: #373737 !important;
}

.btn:hover:before {
  opacity: 1;
  height: 100%;
  transition: height .2s, top .2s, opacity linear;
  top: 0;
}

.btn:hover:after {
  border-color: #373737;
  transition: border .1s linear;
}

.slideshow {
  z-index: 1;
  width: 100%;
  height: calc(100vh - 73px);
  position: relative;
  overflow: hidden;
}

.slideshow .slideshow-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow .slides {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow .slide {
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.slideshow .slide.is-active {
  display: block;
}

.slideshow .slide.is-loaded {
  opacity: 1;
}

.slideshow .slide .caption {
  padding: 0 100px;
}

.slideshow .slide .image-container {
  z-index: 1;
  image-rendering: optimizequality;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow .slide .image-container:before {
  content: "";
  background: #00000080;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow .slide .image-container .image {
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  width: 100%;
  height: 100%;
}

.slideshow .slide .image-container #image-one {
  background-image: url("Asset1.edce60e0.png");
}

.slideshow .slide .image-container #image-two {
  background-image: url("Asset2.212d1b61.png");
}

.slideshow .slide .image-container #image-three {
  background-image: url("Asset3.27a4ed84.png");
  background-position: 50% 135%;
}

.slideshow .slide .image-container #image-four {
  background-image: url("Asset4.4f4ef269.png");
}

.slideshow .slide .image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slideshow .slide-content {
  z-index: 2;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow .slide .title {
  letter-spacing: .15em;
  text-transform: uppercase;
  max-width: 1000px;
  margin: 0 auto 15px;
  font: italic 300 35px / 1.2 Raleway, sans-serif;
}

.slideshow .slide .text {
  max-width: 1000px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.4;
}

.slideshow .slide .btn {
  border-color: #fff;
  margin: 15px 0 0;
}

.slideshow .slide .btn:before {
  background: #fff;
}

.slideshow .pagination {
  cursor: default;
  z-index: 2;
  text-align: center;
  width: 100%;
  height: 12px;
  position: absolute;
  bottom: 35px;
  left: 0;
}

.slideshow .pagination .item {
  cursor: pointer;
  text-indent: -999em;
  z-index: 1;
  width: 46px;
  height: 32px;
  padding: 15px 5px;
  display: inline-block;
  position: relative;
}

.slideshow .pagination .item + .page {
  margin-left: -2px;
}

.slideshow .pagination .item:before {
  content: "";
  background: #ffffff80;
  width: 36px;
  height: 2px;
  transition: background .2s;
  display: block;
  position: absolute;
  top: 15px;
  left: 5px;
}

.slideshow .pagination .item:after {
  z-index: 2;
  background: #fff;
  width: 0;
  transition: width .2s;
}

.slideshow .pagination .item:hover:before, .slideshow .pagination .item.is-active:before {
  background-color: #fff;
}

.slideshow .arrows .arrow {
  cursor: pointer;
  z-index: 3;
  margin: -33px 0 0;
  padding: 10px;
  position: absolute;
  top: 52.9%;
}

.slideshow .arrows .prev {
  left: 10px;
}

.slideshow .arrows .prev:hover .svg {
  left: -10px;
}

.slideshow .arrows .next {
  right: 10px;
}

.slideshow .arrows .next:hover .svg {
  left: 10px;
}

.slideshow .arrows .svg {
  fill: #fff;
  width: 14px;
  height: 26px;
  transition: left .2s;
  position: relative;
  left: 0;
}

.section2 {
  grid-template-columns: 1fr;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 5rem 0;
  display: grid;
}

.section2 .text-section {
  text-align: left;
}

.section2 .text-section p:last-of-type {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
}

.section2 #section-two__image {
  margin-top: 1.5rem;
  display: block;
}

.entry-text {
  opacity: .7;
  margin: 20px 0 11px;
  font-family: Raleway, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.title {
  margin: 0;
  font-family: Raleway, sans-serif;
  font-size: 38px;
  font-style: italic;
}

.title span {
  opacity: .9;
  font-style: normal;
  font-weight: 700;
}

.services {
  color: #fff;
  text-align: center;
  background: #1c1c1c;
  margin: 0 auto;
  padding: 2.5rem 0 7rem;
  font-family: Raleway, sans-serif;
  position: relative;
}

.services .title {
  justify-content: center;
  display: flex;
  position: relative;
}

.services .title:after {
  content: "";
  background-color: #fff;
  border-radius: 25px;
  width: 1.6rem;
  height: 3px;
  display: block;
  position: absolute;
  bottom: -15px;
}

.services .container {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.services .card {
  box-shadow: none;
  cursor: pointer;
  transform-style: preserve-3d;
  border: 1px solid #ffffff0d;
  border-radius: 1.5rem;
  width: 80%;
  height: 200px;
  padding: 1rem;
  transition: box-shadow .3s, transform .5s;
  display: inline-block;
  position: relative;
}

.services .card.flip {
  transition: transform .5s;
  transform: rotateY(180deg);
  box-shadow: 4px 4px 8px #000;
}

.services .card.flip:after {
  left: -1px;
}

.services .card .face {
  backface-visibility: hidden;
  position: absolute;
  inset: 3px;
  transform: translateZ(.1px);
}

.services .card .back {
  transform: rotateY(180deg);
}

.services .card:hover {
  box-shadow: 4px 4px 8px #000;
}

.services .card:after {
  opacity: 0;
  content: "";
  background: linear-gradient(#0000, #0e63ae, #0000);
  width: 1px;
  height: 70px;
  transition: top .6s, opacity .6s;
  position: absolute;
  top: 75%;
  left: -1px;
}

.services .card:hover:after {
  opacity: 1;
  top: 15%;
}

.services .card .more {
  opacity: .5;
  font-size: 15px;
}

.services .card-content {
  background-image: radial-gradient(#ffffff1a 1px, #0000 1px);
  background-position: 50%;
  background-size: 1.1rem 1.1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.services .card-content .icons {
  width: 50px;
  height: 50px;
}

.services .card-content .description {
  padding: 1rem;
  font-size: 15px;
}

.category {
  text-align: left;
  color: #ffffffe6;
  width: 90%;
  margin: 3rem auto 2rem;
  position: relative;
}

.category:after {
  content: "";
  background-color: #fff;
  border-radius: 25px;
  width: 1.6rem;
  height: 3px;
  display: block;
  position: absolute;
  bottom: -15px;
}

#path-section {
  height: 550px;
  margin: 5.5rem auto 2rem;
}

#path-section .container {
  z-index: 3;
  position: relative;
}

@media (width <= 370px) {
  #path-section {
    height: 670px;
  }
}

.section3 {
  color: #fff;
  image-rendering: optimizequality;
  background-image: url("h6-parallax-1.55579c25.png");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: scroll;
  place-items: center;
  width: 100%;
  height: 87.5vh;
  display: grid;
}

.section3 h2 {
  margin: 20px auto 1.75rem;
  font-family: Raleway, sans-serif;
  font-size: 60px;
}

.section3 a {
  cursor: pointer;
  color: #000;
  background: #fff;
  border: none;
  border-radius: 3px;
  margin: 0 auto;
  padding: .7em 1.4em;
  font-size: 16px;
  font-weight: 500;
  transition: transform .3s;
  display: inline-block;
}

.section3 a:hover {
  transform: scale(1.1);
}

#last {
  place-items: start center;
  display: grid;
}

#last .text {
  text-align: center;
  width: 80%;
  max-width: 1200px;
  margin: .5rem auto 5rem;
}

.waves {
  width: 100%;
  height: 40px;
  min-height: 40px;
  max-height: 150px;
  margin-bottom: -.75rem;
  position: relative;
}

.content {
  text-align: center;
  background-color: #fff;
  height: 30vh;
  position: relative;
}

.parallax > use {
  animation: 25s cubic-bezier(.55, .5, .45, .5) infinite move-forever;
}

.parallax > use:first-child {
  animation-duration: 7s;
  animation-delay: -2s;
}

.parallax > use:nth-child(2) {
  animation-duration: 10s;
  animation-delay: -3s;
}

.parallax > use:nth-child(3) {
  animation-duration: 13s;
  animation-delay: -4s;
}

.parallax > use:nth-child(4) {
  animation-duration: 20s;
  animation-delay: -5s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

.footer-content {
  color: #fff;
  background-color: #1c1c1c;
  padding: 0 5% 1rem;
}

.footer-content .container {
  grid-template-columns: 1fr;
  justify-items: start;
  width: 80%;
  margin: 0 auto;
  display: grid;
}

.footer-content .about-text {
  opacity: .8;
  font-size: 16px;
}

.footer-content .navigation {
  text-align: left;
  width: 100%;
  padding-left: 0;
  list-style: none;
}

.footer-content .navigation a, .footer-content .navigation a:visited, .footer-content .navigation a:active {
  color: #ffffffe6;
  transition: color .2s;
}

.footer-content .navigation a:hover, .footer-content .navigation a:visited:hover, .footer-content .navigation a:active:hover {
  color: #0e63ae;
}

.footer-content .navigation li {
  justify-content: left;
  align-items: center;
  gap: 1rem;
  margin: 0 auto .75rem;
  display: flex;
}

.footer-content .navigation li img {
  width: 26px;
  height: 26px;
  display: block;
}

.footer-content .fieldInput {
  border: 1px solid #0000;
  border-radius: 35px;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 3rem auto auto;
  padding-top: 0;
  padding-bottom: 0;
  transition: border-color .2s;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 20px #000;
}

.footer-content .fieldInput:hover, .footer-content .fieldInput:focus-within {
  border: 1px solid #0e63ae;
}

.footer-content .fieldInput .form-input {
  color: #fffc;
  background: none;
  border: none;
  padding: 0 2px;
}

.footer-content .fieldInput .form-input:focus {
  outline: none;
}

.footer-content .fieldInput .form-submit {
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 100px;
  width: 49px;
  height: 49px;
  font-size: 12px;
  transition: background-color .2s;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  box-shadow: 5px -2px 81px 1px #000;
}

.footer-content .fieldInput .form-submit:hover {
  background-color: #0e63ae;
}

.footer-content .social_profile {
  margin-top: 40px;
}

.footer-content .social_profile .list {
  justify-content: space-evenly;
  align-items: center;
  gap: .8rem;
  display: flex;
}

.footer-content .social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.footer-content .social_profile ul li a {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  border: 1px solid #fff3;
  border-radius: 5rem;
  width: 50px;
  height: 50px;
  line-height: 3.2rem;
  transition: all .3s;
  display: block;
}

.footer-content .social_profile ul li a:hover {
  color: #fff;
  background: #0e63ae;
  border: 0;
  box-shadow: 4px 4px 8px #000;
}

.footer-content .copyright {
  color: #fff;
  text-align: center;
  opacity: .8;
  border-top: 1px solid #fff6;
  width: 87.5%;
  margin: 1rem auto 0;
  padding-top: 1.5rem;
  font-size: 16px;
}

.footer-content .copyright a {
  color: #0e63ae;
  cursor: pointer;
  transition: all .2s;
}

.footer-content .copyright a:hover {
  color: #ff3666;
}

@media (width >= 768px) {
  .header__links {
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-size: .875rem;
    transition: color .3s ease-in-out;
    display: flex;
    position: relative;
  }

  .header__links a {
    color: #75788a;
    text-transform: uppercase;
    font-weight: 300;
    position: relative;
  }

  .header__links a:hover {
    color: #fff;
  }

  .header__links a:after {
    content: "";
    background-color: #0000;
    border-radius: 25px;
    height: 3px;
    transition: background-color .3s ease-in-out;
    display: block;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
  }

  .header__links a:hover:after {
    background-color: #fff;
  }

  .hamburger, .header__menu, .overlay {
    display: none;
  }

  header .button-link {
    cursor: pointer;
    color: #fff;
    background: #0e63ae;
    border: none;
    border-radius: 50px;
    padding: .6em 1.2em;
    font-size: 15px;
    display: block;
  }

  header .button-link:hover {
    opacity: .8;
    transition: opacity .3s;
  }

  .logo {
    align-self: center;
    align-items: center;
    height: 100%;
    display: flex;
  }

  .nav__list {
    gap: 3rem;
    display: flex;
  }

  .slideshow .slide .title {
    font: italic 300 50px / 1.2 Raleway, sans-serif;
  }

  .slideshow .arrows .arrow {
    padding: 20px;
    top: 50%;
  }

  .slideshow .arrows .prev {
    left: 30px;
  }

  .slideshow .arrows .next {
    right: 30px;
  }

  .section2 {
    grid-template-columns: 1.75fr 2fr;
  }

  .section2 #section-two__image {
    margin-top: 0;
  }

  .title {
    font-size: 40px;
  }

  .services .container {
    align-items: center;
    width: 90%;
  }

  .services .card {
    width: calc(25% - .75rem);
    min-width: 250px;
  }

  .services .services-container {
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    gap: 1rem;
    width: 90%;
    margin: 0 auto;
    display: grid;
  }

  .waves {
    height: 15vh;
    min-height: 100px;
  }

  .content {
    height: 20vh;
  }

  .footer-content .container {
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    width: 100%;
  }

  .footer-content .container .category {
    opacity: .85;
    width: 100%;
  }
}

:root {
  --color-accent-dark: #0e63ae;
  --color-secondary: #ccc;
  --transition-duration: .2s;
  --transition-timing-function: linear;
  --transition-delay: 0s;
  --negative-multiplier: -1;
}

.timeline {
  --wrapper-width: 900px;
  max-width: var(--wrapper-width);
  width: 95%;
  margin: auto;
}

.timeline__stepper {
  --step-border-width: 3px;
  --offset-to-slider-content: 4rem;
  margin-bottom: var(--offset-to-slider-content);
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  display: grid;
  position: relative;
}

@media (width >= 530px) {
  .timeline__stepper {
    justify-content: space-around;
    gap: 0;
    display: flex;
  }
}

@media (width >= 700px) {
  .timeline__stepper {
    --offset-to-slider-content: 3rem;
  }
}

.timeline__step {
  cursor: pointer;
  width: 100%;
  position: relative;
}

@media (width >= 530px) {
  .timeline__step {
    width: 100%;
  }
}

.timeline__icon {
  --timeline-icon-dimensions: 45px;
  width: var(--timeline-icon-dimensions);
  max-height: var(--timeline-icon-dimensions);
  transition: opacity var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
  height: auto;
  margin: 0 auto;
  display: block;
  position: relative;
}

@media (width >= 700px) {
  .timeline__icon {
    --timeline-icon-dimensions: 60px;
    margin-bottom: 30px;
  }
}

.timeline__icon--active {
  fill: #0e63ae;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.timeline__step-title {
  --timeline-dot-dimensions: 11px;
  --extra-offset-fox-pixelperfect: -1px;
  text-align: center;
  border-top: var(--step-border-width) solid var(--color-secondary);
  margin-bottom: 0;
  padding-top: 30px;
  font-family: Raleway, sans-serif;
  line-height: 1.7;
  display: none;
  position: relative;
}

@media (width >= 700px) {
  .timeline__step-title {
    display: block;
  }
}

.timeline__step-title:before, .timeline__step-title:after {
  top: var(--dot-top-position);
  width: var(--timeline-dot-dimensions);
  height: var(--timeline-dot-dimensions);
  content: "";
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 50%;
}

.timeline__step-title:before {
  --dot-top-position: calc(var(--timeline-dot-dimensions) / 2 * var(--negative-multiplier)  + var(--extra-offset-fox-pixelperfect));
  border: 2px solid var(--color-secondary);
  z-index: 5;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%);
}

.timeline__step-title:after {
  --timeline-dot-dimensions: 25px;
  --dot-top-position: calc(var(--timeline-dot-dimensions) / 2 * var(--negative-multiplier)  + var(--extra-offset-fox-pixelperfect));
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%)scale(0);
}

.timeline__step-active-marker {
  --slide-pos-y: none;
  --slide-pos-x: none;
  --slide-width: auto;
  --transition-timing-function: cubic-bezier(0, 0, 0, 1.2);
  top: var(--slide-pos-y);
  width: var(--slide-width);
  height: var(--step-border-width);
  transform: translateX(var(--slide-pos-x));
  transition: transform var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
  background-color: #0e63ae;
  display: none;
  position: absolute;
  left: 0;
}

@media (width >= 700px) {
  .timeline__step-active-marker {
    display: block;
  }
}

.timeline__step.is-active {
  cursor: default;
}

.timeline__step.is-active .timeline__step-title {
  font-weight: 600;
}

.timeline__step.is-active .timeline__step-title:before {
  transition: background-color var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
  background-color: var(--color-accent-dark);
  border-color: var(--color-accent-dark);
}

.timeline__step.is-active .timeline__step-title:after {
  transition: transform var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
  opacity: .35;
  background-color: #0e63ae;
  transform: translate(-50%, 7%)scale(1);
}

.timeline__step.is-active .timeline__icon--default, .timeline__step:not(.is-active):hover .timeline__icon--default {
  opacity: 0;
}

.timeline__step.is-active .timeline__icon--active, .timeline__step:not(.is-active):hover .timeline__icon--active {
  opacity: 1;
}

.timeline__slides {
  --slides-container-height: 0px;
  --content-offset-x: 20px;
  --content-offset-y: 30px;
  --decoration-blur-spread: 20px;
  --decoration-opacity: .7;
  --decoration-pos-x: 0;
  --decoration-pos-y: 20px;
  --decoration-scale: 1;
  height: var(--slides-container-height);
  background-color: #fff;
  border-radius: 5px;
  width: 600px;
  max-width: 100%;
  margin: auto;
  position: relative;
}

@media (width >= 700px) {
  .timeline__slides {
    --content-offset-x: 30px;
    --content-offset-y: 40px;
  }
}

.timeline__slides:before {
  content: "";
  opacity: var(--decoration-opacity);
  background-color: var(--color-accent-dark);
  filter: blur(var(--decoration-blur-spread));
  transform: translate3d(var(--decoration-pos-x), var(--decoration-pos-y), 0) scale(var(--decoration-scale));
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.timeline__slide {
  padding: var(--content-offset-y) var(--content-offset-x);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.timeline__slide.is-active {
  transition: opacity var(--transition-duration) var(--transition-timing-function) var(--transition-delay);
  opacity: 1;
  visibility: visible;
}

.timeline__slide-title {
  margin-top: 0;
  font-family: Raleway, sans-serif;
}

.timeline__slide-content {
  line-height: 1.7;
}

.timeline__slide-content p:first-child {
  margin-top: 0;
}

.timeline__slide-content p:last-child {
  margin-bottom: 0;
}

/*# sourceMappingURL=index.a34f278c.css.map */
